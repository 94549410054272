import { Slot } from "@radix-ui/react-slot"
import * as React from "react"


export interface BlurhashProps extends React.PropsWithChildren {
  hash: string | null | undefined
  className?: string
  figureClassName?: string
}

export const Blurhash = ({ children }: BlurhashProps) => {
  return (
    <Slot>{children}</Slot>
  )
}


Blurhash.displayName = "Blurhash"

